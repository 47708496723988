.App {
  text-align: center;
  flex: 1;
  alignItems: 'center';
  justifyContent: 'center';
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@font-face {
  font-family: "Chivo Mono";
  src: local("Chivo"),
   url("./fonts/ChivoMono-Regular.ttf") format("truetype");
  font-weight: Regular;
 }

 .font-face-cm {
  font-family: "Chivo Mono";
  color: white;

 }

.App-header {
  background-color: black;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  /* color: white; */
  overflow: hidden !important;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;

}

.link {
  color: white;
}

.link:hover {
  color: gray;
}

